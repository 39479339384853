import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import React from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletModalRoot: React.FC<Props> = ({ isOpen, onClose, children }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="#282828e6" />
      <ModalContent
        maxW="320px"
        boxShadow="0px 8px 28px #0a061c40"
        padding="12px"
        borderRadius="20px"
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export default WalletModalRoot;
