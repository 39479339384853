import { createIcon } from "@chakra-ui/icons";

const MetaMaskIcon = createIcon({
  displayName: "MetaMask",
  viewBox: "0 0 25 24",
  path: (
    <g opacity="0.33">
      <path
        d="M7.27357 17.25L5.0918 12.75L8.00082 15L10.1826 15.75L8.72808 14.25L10.1826 13.5V7.5H13.8189V13.5L15.2734 14.25L13.8189 15.75L16.0006 15L18.9097 12.75L16.7279 17.25L14.5461 18.75L13.8189 19.125H10.1826L9.45533 18.75L7.27357 17.25Z"
        fill="#B8B5C4"
      />
      <path d="M10.1836 24V21.75H13.8199V24H10.1836Z" fill="#B8B5C4" />
      <path
        d="M7.27441 21.7498L10.0016 18.7498L10.1834 23.9998L7.27441 21.7498Z"
        fill="#726A88"
      />
      <path
        d="M16.7285 21.7498L14.0013 18.7498L13.8195 23.9998L16.7285 21.7498Z"
        fill="#726A88"
      />
      <path
        d="M10.1827 19.5L2.91016 22.5L7.27369 21.75L10.1827 19.5Z"
        fill="#291E4B"
      />
      <path
        d="M13.8192 19.5L21.0918 22.5L16.7283 21.75L13.8192 19.5Z"
        fill="#291E4B"
      />
      <path
        d="M24.0011 17.25L22.5466 12L16.7285 10.5L18.9103 12.75L16.7285 17.25H24.0011Z"
        fill="#B8B5C4"
      />
      <path
        d="M0.00185204 17.25L1.45636 12L7.27441 10.5L5.09265 12.75L7.27441 17.25H0.00185204Z"
        fill="#B8B5C4"
      />
      <path
        d="M1.45647 23.25L0.00195312 17.25H7.27452L10.1835 19.5L1.45647 23.25Z"
        fill="#726A88"
      />
      <path
        d="M22.5465 23.25L24.001 17.25H16.7284L13.8194 19.5L22.5465 23.25Z"
        fill="#726A88"
      />
      <path d="M10.1836 21.75V19.5H13.8199V21.75H10.1836Z" fill="#291E4B" />
      <path
        d="M16.7303 10.5L13.8213 7.50003L23.2756 0L24.0029 4.50002L23.2756 8.25003V10.5L22.5484 12L16.7303 10.5Z"
        fill="#291E4B"
      />
      <path
        d="M16.3147 15.0002L18.1848 13.5002H13.8213L16.3147 15.0002Z"
        fill="#726A88"
      />
      <path
        d="M18.9121 12.75L13.8213 7.5L18.1848 13.5L18.9121 12.75Z"
        fill="#948EA5"
      />
      <path
        d="M13.8208 15.75L16.3662 15L15.0717 14.25L13.8208 15.75Z"
        fill="#291E4B"
      />
      <path d="M13.8213 7.5L18.1848 13.5H13.8213V7.5Z" fill="#948EA5" />
      <path
        d="M15.2758 3.00001L13.8213 7.50003L23.2756 0L15.2758 3.00001Z"
        fill="#726A88"
      />
      <path
        d="M7.27262 10.5L10.1816 7.50003L0.72731 0L5.43594e-05 4.50002L0.72731 8.25003V10.5L1.45457 12L7.27262 10.5Z"
        fill="#291E4B"
      />
      <path
        d="M7.68819 15.0002L5.8181 13.5002H10.1816L7.68819 15.0002Z"
        fill="#726A88"
      />
      <path
        d="M5.09085 12.75L10.1816 7.5L5.8181 13.5L5.09085 12.75Z"
        fill="#948EA5"
      />
      <path
        d="M10.1821 15.75L7.63672 15L8.93123 14.25L10.1821 15.75Z"
        fill="#291E4B"
      />
      <path d="M10.1816 7.5L5.8181 13.5H10.1816V7.5Z" fill="#948EA5" />
      <path
        d="M8.72713 3.00001L10.1816 7.50003L0.727311 0L8.72713 3.00001Z"
        fill="#726A88"
      />
      <path
        d="M13.8193 7.50002H10.183L8.72852 3H15.2738L13.8193 7.50002Z"
        fill="#B8B5C4"
      />
    </g>
  ),
});
export default MetaMaskIcon;
