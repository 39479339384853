import { Text } from "@chakra-ui/react";

import WalletModalHeader from "~web/components/WalletModal/WalletModalHeader";
import WalletModalRoot from "~web/components/WalletModal/WalletModalRoot";
import WalletModalBody from "~web/components/WalletModal/WalletModalBody";
import WalletModalFooter from "~web/components/WalletModal/WalletModalFooter";

import ErrorConnectingIcon from "./icons/ErrorConnectingIcon";

type Props = {
  title: string;
  description: string;
  isVisible: boolean;
  hide: () => void;
  Footer: ({ hide }: { hide: () => void }) => JSX.Element;
};

const ErrorModal: React.FC<Props> = ({
  title,
  description,
  isVisible,
  hide,
  Footer,
}) => {
  return (
    <WalletModalRoot isOpen={isVisible} onClose={hide}>
      <WalletModalHeader>
        <ErrorConnectingIcon w="80px" h="80px" mb="16px" />
        <Text>{title}</Text>
      </WalletModalHeader>
      <WalletModalBody>{description}</WalletModalBody>
      <WalletModalFooter>
        <Footer hide={hide} />
      </WalletModalFooter>
    </WalletModalRoot>
  );
};

export default ErrorModal;
