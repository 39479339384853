export const POLYGON_MATIC_CHAIN_ID = 137;
export const ETHEREUM_MAINNET_CHAIN_ID = 1;
export const POLYGON_MATIC_CURRENCY = "Matic";
export const ETHEREUM_MAINNET_CURRENCY = "Eth";

export const CONSTANTS = {
  POLYGON_MATIC_CHAIN_ID,
  ETHEREUM_MAINNET_CHAIN_ID,
  POLYGON_MATIC_CURRENCY,
  ETHEREUM_MAINNET_CURRENCY,
};

export default CONSTANTS;
