import "~web/styles/globals.css";
import "~web/styles/components/Editor.css";
import "~web/styles/landing.scss";
import "nprogress/nprogress.css";

import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import { Provider as WagmiProvider } from "wagmi";
import { NextPageContext } from "next";
import cookies from "next-cookies";
import nProgress from "nprogress";
import { Router } from "next/router";
import * as FullStory from "@fullstory/browser";
import { useEffect } from "react";

import theme from "~web/styles/theme";
import { POLYGON_MATIC_CHAIN_ID } from "~web/constants";
import { AlchemyPolygonMaticProvider, connectors } from "~web/connectors";
import { AuthProvider } from "~web/state/auth";
import SignInModal from "~web/components/SignInModal";

import { Cookies, useApollo } from "../apiClient/client";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

interface InitialProps {
  cookies: Cookies;
}

type Props = AppProps & InitialProps;

const MemApp = ({ Component, pageProps, cookies }: Props) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    FullStory.init({
      orgId: "18XBNA",
      devMode: process.env.NODE_ENV !== "production",
    });
  }, []);
  const client = useApollo(pageProps ? pageProps.initialApolloState : null);
  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <WagmiProvider
          autoConnect
          connectors={connectors({ chainId: POLYGON_MATIC_CHAIN_ID })}
          provider={AlchemyPolygonMaticProvider}
        >
          <AuthProvider initAccessToken={cookies["accessToken"]}>
            <Component {...pageProps} />
            <SignInModal />
          </AuthProvider>
        </WagmiProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
};

MemApp.getInitialProps = ({ ctx }: { ctx: NextPageContext }): InitialProps => {
  return {
    cookies: cookies(ctx),
  };
};

export default MemApp;
