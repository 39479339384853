import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

import breakpoints from "./breakpoints";

const inputSelectOutlineStyle = {
  border: "none",
  boxShadow: "inset 0 0 0 1.5px var(--chakra-colors-gray-600)",
  borderRadius: "12px",
  fontSize: "15px",
  fontWeight: "600",
  lineHeight: "1.2",
  background: "white",
  _placeholder: {
    color: "gray.600",
  },
  padding: "15px 18px",
};

export default extendTheme({
  breakpoints,

  initialColorMode: "light",
  useSystemColorMode: false,

  colors: {
    background: "#fff",
    white: "#fff",
    black: "#000",
    gray: {
      100: "hsla(240, 10%, 96%, 1)", // F4F4F6
      200: "hsla(252, 33%, 97%, 1)", // F6F5FA
      300: "hsla(257, 26%, 95%, 1)", // F0EEF5
      400: "hsla(253, 20%, 91%, 1)", // E6E4ED
      500: "hsla(231, 14%, 81%, 1)", // C9CBD6
      550: "hsla(230, 8%, 71%, 1)", // B0B2BC
      600: "hsla(252, 11%, 74%, 1)", // B8B5C4
      650: "hsla(256, 11%, 60%, 1)", // 948EA5
      700: "hsla(256, 12%, 47%, 1)", // 726A88
      800: "hsla(251, 15%, 22%, 1)", // 322F3F
      900: "hsla(255, 43%, 21%, 1)", // 291E4B
    },
    salmon: {
      200: "hsla(0, 100%, 97%, 1)",
      500: "hsla(0, 100%, 71%, 1)",
    },
    blue: {
      500: "hsla(234, 100%, 62%, 1)",
      600: "hsla(248, 100%, 50%, 1)",
    },
    green: {
      400: "hsla(148, 61%, 63%, 1)",
    },
  },

  fonts: {
    body: "Inter",
    heading: "Inter",
  },

  styles: {
    global: {
      body: {
        fontFamily: "body",
      },
    },
  },

  components: {
    Button: {
      baseStyle: {
        fontWeight: "600",
        _hover: {
          opacity: 0.8,
        },
        _active: {
          opacity: 0.6,
        },
        _focus: {
          opacity: 0.6,
        },
      },
      sizes: {
        sm: {
          borderRadius: "5px",
          fontSize: "14px",
          px: "12px",
          height: "26px",
        },
        md: {
          borderRadius: "full",
          fontSize: "15px",
          px: "16px",
          height: "32px",
        },
      },
      variants: {
        regular: {
          bg: "gray.400",
          color: "gray.900",
        },
        dark: {
          bg: "gray.900",
          color: "gray.100",
        },
        transparent: {
          bg: "transparent",
          color: "gray.900",
        },
        selected: {
          bg: "gray.700",
          color: "white",
        },
        submit: {
          bg: "blue.500",
          color: "white",
        },

        "shiny.blue": {
          bg: "linear-gradient(#5671FF, #2400FF)",
          color: "white",
          boxShadow: "inset 0px -6px 12px rgba(255, 255, 255, 0.5)",
          _hover: {
            _disabled: {
              opacity: 0.4,
              bg: "linear-gradient(#5671FF, #2400FF)",
              color: "white",
            },
          },
        },
        "shiny.white": {
          bg: "white",
          boxShadow: "inset 0px -6px 12px #291e4b1a",
          color: "gray.900",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray.500",
        },
        "shiny.red": {
          bg: "hsla(0, 100%, 67%, 1)",
          color: "white",
          boxShadow: "inset 0px -6px 12px rgba(255, 255, 255, 0.5)",
        },

        /*   Special cases   */
        "topicToggle.selected": {
          borderRadius: "8px",
          background: "gray.400",
          border: "none",
          color: "#706C7C",
          boxSizing: "border-box",
          fontSize: "15px",
        },
        "topicToggle.unselected": {
          borderRadius: "8px",
          boxShadow: "inset 0 0 0 1.5px var(--chakra-colors-gray-400)",
          color: "#706C7C",
          fontSize: "15px",
        },

        full: {
          width: "100%",
          height: "fit-content",
          padding: "10px",
          backgroundColor: "gray.200",
          fontSize: "20px",
          fontWeight: "semibold",
          lineHeight: "28px",
          borderRadius: "12px",
          color: "gray.700",
        },

        toggle: {
          height: "26px",
          width: "26px",
          minWidth: 0,
          borderRadius: "6px",
          paddingStart: 0,
          paddingEnd: 0,
          color: "gray.650",
        },
        "toggle.active": (props: StyleFunctionProps) => ({
          ...props.theme.components.Button.variants.toggle,
          background: "gray.400",
        }),
      },
    },
    FormLabel: {
      variants: {
        indented: {
          fontSize: "15px",
          fontWeight: "600",
          lineHeight: "2rem",
          mx: "16px",
          color: "input.label",
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          ...inputSelectOutlineStyle,
        },
      },
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            ...inputSelectOutlineStyle,
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            ...inputSelectOutlineStyle,
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            ...inputSelectOutlineStyle,
          },
        },
        filled: {
          field: {
            bg: "#EBE8F2BF",
            borderRadius: 20,
            color: "#291E4BA8",
          },
        },
      },
    },
  },
});
