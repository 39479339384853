import { gql, useApolloClient } from "@apollo/client";
import { useContext, useMemo, useState } from "react";
import React from "react";
import noop from "lodash/noop";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { Connector } from "wagmi";
import { Button } from "@chakra-ui/react";

import { useWalletAuthMutation } from "~web/apiClient/hooks";
import { LoginMutation } from "~web/generated/graphql";
import ErrorModal from "~web/components/ErrorModal";

type AuthUser = {
  address: string;
  username?: string;
};

type AuthData = {
  user?: AuthUser;
  logout: () => void;
  login: (connector: Connector) => void;
  loading: boolean;
  isAuthed: boolean;
  loginModal: {
    isVisible: boolean;
    show: () => void;
    hide: () => void;
  };
};

const AuthContext = React.createContext<AuthData>({
  login: noop,
  logout: noop,
  loading: false,
  isAuthed: false,
  loginModal: {
    isVisible: false,
    show: noop,
    hide: noop,
  },
});

const LOGIN_MUTATION = gql`
  mutation Login {
    login {
      accessToken
    }
  }
`;

const ERROR_MODAL: {
  [code: string]: {
    title: string;
    description: string;
    Footer: ({ hide }: { hide: () => void }) => JSX.Element;
  };
} = {
  NOT_IN_WHITELIST: {
    title: "You're not on the whitelist",
    description:
      "Mem is a private beta right now. You can apply to get an invite.",
    Footer: () => (
      <a
        href="https://memprotocol.typeform.com/members"
        target="_blank"
        style={{ width: "100%" }}
        rel="noreferrer"
      >
        <Button variant="full">Join waitlist</Button>
      </a>
    ),
  },
  default: {
    title: "An error occurred",
    description: "Please try again",
    Footer: ({ hide }) => (
      <Button variant="full" onClick={hide}>
        Close
      </Button>
    ),
  },
};

export const AuthProvider: React.FC<{ initAccessToken?: string }> = ({
  children,
  initAccessToken,
}) => {
  const [user, setUser] = useState<AuthUser | undefined>(
    // This will error if the jwt is messed up
    initAccessToken ? jwtDecode(initAccessToken) : undefined
  );
  const [mutateLogin, { loading }] =
    useWalletAuthMutation<LoginMutation>(LOGIN_MUTATION);
  const client = useApolloClient();

  const [loginVisible, setLoginVisible] = useState(false);
  const loginModal = useMemo(
    () => ({
      isVisible: loginVisible,
      show: () => setLoginVisible(true),
      hide: () => setLoginVisible(false),
    }),
    [loginVisible]
  );

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const showError = (code: string | null) => {
    setLoginVisible(false);
    setErrorVisible(true);
    setErrorCode(code);
  };

  const refreshGraphQL = async () => {
    await client.cache.reset();
    await client.reFetchObservableQueries();
  };

  const login = async (connector: Connector) => {
    const { data, errors } = await mutateLogin(connector);
    const accessToken = data?.login?.accessToken;

    if (
      errors &&
      errors.some((e) => e.extensions.code === "NOT_IN_WHITELIST")
    ) {
      showError("NOT_IN_WHITELIST");
      return;
    }

    if (!accessToken && !errors) {
      // Assume the user cancelled or something during the process
      return;
    }

    if (!accessToken || errors) {
      showError(null);
      console.error(errors || "No access token returned");
      return;
    }

    loginModal.hide();
    setUser(jwtDecode(accessToken));
    refreshGraphQL();
  };

  const logout = () => {
    setUser(undefined);
    Cookies.remove(
      "accessToken",
      process.env.NODE_ENV === "production" ? { domain: ".mem.co" } : undefined
    );
    refreshGraphQL();
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, login, logout, isAuthed: !!user, loginModal }}
    >
      {children}
      <ErrorModal
        isVisible={errorVisible}
        hide={() => setErrorVisible(false)}
        {...ERROR_MODAL[
          errorCode && Object.keys(ERROR_MODAL).includes(errorCode)
            ? errorCode
            : "default"
        ]}
      />
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
