import { ModalFooter } from "@chakra-ui/react";

const WalletModalFooter: React.FC<unknown> = ({ children }) => (
  <ModalFooter
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "0px",
    }}
  >
    {children}
  </ModalFooter>
);

export default WalletModalFooter;
