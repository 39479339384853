import { Flex } from "@chakra-ui/react";

const WalletModalPopup: React.FC<unknown> = ({ children }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      marginBottom: "-85px",
      fontSize: "20px",
      fontWeight: "medium",
      px: "40px",
      py: "12px",
      color: "gray.200",
      borderRadius: "12px",
      border: "1px solid",
      borderColor: "gray.200",
    }}
  >
    {children}
  </Flex>
);

export default WalletModalPopup;
