import { Button } from "@chakra-ui/react";
import { useConnect } from "wagmi";

import WalletConnectIcon from "~web/components/icons/WalletConnectIcon";
import MetaMaskIcon from "~web/components/icons/MetaMaskIcon";
import WalletModalHeader from "~web/components/WalletModal/WalletModalHeader";
import WalletModalRoot from "~web/components/WalletModal/WalletModalRoot";
import WalletModalBody from "~web/components/WalletModal/WalletModalBody";
import WalletModalFooter from "~web/components/WalletModal/WalletModalFooter";
import WalletModalPopup from "~web/components/WalletModal/WalletModalPopup";
import { useAuthContext } from "~web/state/auth";

const SignInModal: React.FC<unknown> = () => {
  const { login, loading, loginModal } = useAuthContext();

  const [{ data }] = useConnect();
  const [metamask, walletConnect] = data.connectors;

  return (
    <WalletModalRoot isOpen={loginModal.isVisible} onClose={loginModal.hide}>
      <WalletModalHeader>Sign in with Wallet</WalletModalHeader>
      <WalletModalBody>
        To take actions on Mem, you need to authenticate
      </WalletModalBody>
      <WalletModalFooter>
        <Button
          variant="full"
          leftIcon={<WalletConnectIcon w="24px" h="24px" />}
          onClick={() => login(walletConnect)}
        >
          WalletConnect
        </Button>
        <Button
          variant="full"
          leftIcon={<MetaMaskIcon w="24px" h="24px" />}
          onClick={() => login(metamask)}
        >
          MetaMask
        </Button>
        {loading && <WalletModalPopup>Awaiting connection...</WalletModalPopup>}
      </WalletModalFooter>
    </WalletModalRoot>
  );
};

export default SignInModal;
