import { createIcon } from "@chakra-ui/icons";

const ErrorConnectingIcon = createIcon({
  displayName: "Error Connecting",
  viewBox: "0 0 80 80",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7159 68.2843C-3.90506 52.6633 -3.90506 27.3367 11.7159 11.7157C27.3369 -3.90524 52.6635 -3.90524 68.2845 11.7157C83.9054 27.3367 83.9054 52.6633 68.2845 68.2843C52.6635 83.9052 27.3369 83.9052 11.7159 68.2843ZM32.9291 40L20.9083 27.9792L27.9794 20.9081L40.0002 32.9289L52.021 20.9081L59.0921 27.9792L47.0712 40L59.0921 52.0208L52.021 59.0919L40.0002 47.0711L27.9794 59.0919L20.9083 52.0208L32.9291 40Z"
      fill="#C9CBD6"
    />
  ),
});
export default ErrorConnectingIcon;
