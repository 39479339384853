import { ModalHeader } from "@chakra-ui/react";

const WalletModalHeader: React.FC<unknown> = ({ children }) => (
  <ModalHeader
    sx={{
      textAlign: "center",
      color: "gray.900",
      fontSize: "28px",
      lineHeight: "32px",
      marginBottom: "10px",
      padding: "0px",
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "33px",
    }}
  >
    {children}
  </ModalHeader>
);

export default WalletModalHeader;
