import { createIcon } from "@chakra-ui/icons";

const WalletConnectIcon = createIcon({
  displayName: "WalletConnect",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
        fill="transparent"
      />
      <path
        d="M7.83165 9.36918C10.1324 7.11652 13.8627 7.11652 16.1635 9.36918L16.4404 9.64029C16.5555 9.75293 16.5555 9.93554 16.4404 10.0482L15.4932 10.9756C15.4357 11.0319 15.3424 11.0319 15.2849 10.9756L14.9039 10.6025C13.2988 9.031 10.6964 9.031 9.09133 10.6025L8.68325 11.002C8.62573 11.0584 8.53247 11.0584 8.47496 11.002L7.52772 10.0746C7.41269 9.962 7.41269 9.77938 7.52772 9.66675L7.83165 9.36918ZM18.1225 11.2872L18.9655 12.1126C19.0806 12.2252 19.0806 12.4078 18.9655 12.5205L15.1642 16.2423C15.0492 16.355 14.8627 16.355 14.7476 16.2423C14.7476 16.2423 14.7476 16.2423 14.7476 16.2423L12.0497 13.6008C12.0209 13.5727 11.9743 13.5727 11.9455 13.6008C11.9455 13.6008 11.9455 13.6008 11.9455 13.6008L9.24765 16.2423C9.13261 16.355 8.9461 16.355 8.83106 16.2423C8.83106 16.2423 8.83106 16.2423 8.83105 16.2423L5.02964 12.5204C4.9146 12.4078 4.9146 12.2252 5.02964 12.1125L5.87268 11.2871C5.98772 11.1745 6.17423 11.1745 6.28927 11.2871L8.98725 13.9287C9.01601 13.9568 9.06264 13.9568 9.0914 13.9287C9.0914 13.9287 9.0914 13.9287 9.0914 13.9287L11.7892 11.2871C11.9043 11.1745 12.0908 11.1745 12.2058 11.2871C12.2058 11.2871 12.2058 11.2871 12.2058 11.2871L14.9038 13.9287C14.9326 13.9568 14.9792 13.9568 15.008 13.9287L17.7059 11.2872C17.8209 11.1745 18.0075 11.1745 18.1225 11.2872Z"
        fill="#B8B5C4"
      />
    </>
  ),
});
export default WalletConnectIcon;
