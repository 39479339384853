import { ModalBody } from "@chakra-ui/react";

const WalletModalBody: React.FC<unknown> = ({ children }) => (
  <ModalBody
    sx={{
      textAlign: "center",
      maxW: "200px",
      marginX: "auto",
      paddingX: "0px",
      color: "gray.600",
      fontWeight: "semibold",
      fontSize: "16px",
      lineHeight: "1.2em",
      paddingY: "10px",
      minH: "120px",
    }}
  >
    {children}
  </ModalBody>
);

export default WalletModalBody;
